import ALL_PASSENGERS from '@/graphql/passengers/query/getAllPassengersByUserAutocomplete.gql'

const passengersByUser = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)
const user = ref(null)

export function passengersByUserAutocomplete(params: any) {
    const apolloQuery = ALL_PASSENGERS
    const variables = {
        search: '%' + params.search + '%',
        orderBy: [
            {
                column: 'FIRST_NAME',
                order: params.sortDesc ? 'DESC' : 'ASC'
            }
        ],
        first: 10,
        page: 1
    }

    if (user.value) {
        variables.user_uuid = user.value
    }
    
    const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

    return result
}

export async function searchPassengersByUserCb(queryString: any, cb: (arg0: any) => void) {
    const { $showError, $sentry } = useNuxtApp()
    try {
        const results = await passengersByUserAutocomplete({ search: queryString })
        cb(results?.getAllPassengers?.data ?? [])
    } catch (err) {
        $showError(err)
        $sentry(err, 'searchPassengersByUserCb')
    }
}

export function getSearchPassengersByUser(entityPassenger: any, entityPassengers: any) {
    if (firstTime.value === true) {
        firstTime.value = false
        searchPassengersByUser('', entityPassenger, entityPassengers, true)
    }

    return passengersByUser.value
}

export async function searchPassengersByUser(queryString: any, entityPassenger: any, entityPassengers: any[], refresh = false) {

    const { $showError, $sentry } = useNuxtApp()
    try {
        const results = await passengersByUserAutocomplete({ search: queryString })
        const remotePassengers = results?.getAllPassengers?.data?.map((i: any) => {
            return {
                label: i.first_name + ' ' + i.last_name,
                value: i.uuid
            }
        }) ?? []
        const passengersToAdd: { label: any; value: any }[] = []
        entityPassengers?.forEach((j: any) => {
            if (!remotePassengers.find((k: any) => k.value === j.uuid)) {
                passengersToAdd.push({
                    label: j.first_name + ' ' + j.last_name,
                    value: j.uuid
                })
            }
        })
        if (entityPassenger && !remotePassengers.find((k: any) => k.value === entityPassenger.uuid)) {
            passengersToAdd.push({
                label: entityPassenger.first_name + ' ' + entityPassenger.last_name,
                value: entityPassenger.uuid
            })
        }
        if (refresh) {
            key.value = 0
        }
        if (key.value === 0) {
            key.value = setCustomKeyVariable()
        }
        passengersByUser.value = [...passengersToAdd, ...remotePassengers]
        firstTime.value = false

        if (entityPassenger) {
            getSearchPassengersByUser(entityPassenger, entityPassengers)
        }
    } catch (err) {
        $showError(err)
        $sentry(err, 'searchPassengersByUser')
    }
}

export function getPasengersByUserKey() {
    return key.value
}

export function initialPassengersByUser(userUuid: any) {
    user.value = userUuid
    key.value = 0
    passengersByUser.value = []
    firstTime.value = true
}